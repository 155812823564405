import image1 from "../components/projects/primal.png";
import chautari from "../components/projects/chautari.png";
import image2 from "../components/projects/vibecorner.png";
import image3 from "../components/projects/centrepoint.png";
import image4 from "../components/projects/embassy.png";
import ScrollReveal from "./ScrollReveal";
import ProjectCard from "../components/projects/ProjectCard";

const projectData = [
  {
    image: image1,
    service: "Pos System",
    title: "Primal Booze & Feed, Sai Ying Pun",
    description:
      "We have setup sombapos system with wifi printing. Export report to accounting system. Daily sales report, Monthly report, Category and item based reports and many more.",
    technologies: ["Sql", "SambaPos"],
    link: "#contact",
  },
  {
    image: chautari,
    service: "Pos System",
    title: "Chautari Restaurant, Sheung Wan",
    description:
      "We have setup sombapos system with wifi printing. Export report to accounting system. Daily sales report, Monthly report, Category and item based reports and many more.",
    technologies: ["Sql", "SambaPos"],
    link: "#contact",
  },

  {
    image: image2,
    service: "Website",
    title: "Vibe Corner, Tung Chung",
    description:
      "Modern Website created with modern web technologies to match the brands identy and great online presence.",
    technologies: ["Mysql", "Laravel", "Tailwind Css", "React"],
    link: "https://vibecorner.com.hk",
  },

  {
    image: image3,
    service: "Website",
    title: "Centre Point, Wanchai",
    description:
      "Go to settings and type in  word wrap Change  Editor: Word Wrap from   to   This does exactly what I was wanting in the first place",
    technologies: ["Mysql", "Laravel", "Tailwind Css", "React"],
    link: "https://centrepoint.hk",
  },

  {
    image: image4,
    service: "Website",
    title: "Embassy, Wanchai",
    description:
      "Go to settings and type in  word wrap Change  Editor: Word Wrap from   to   This does exactly what I was wanting in the first place",
    technologies: ["Mysql", "Django", "Tailwind Css", "React"],
    link: "https://embassy.com.hk",
  },
];

const Projects = () => {
  return (
    <div
      id="projects"
      className="flex min-h-screen w-full flex-col items-center justify-center gap-16 pt-20 px-3 md:px-14 md:py-24"
    >
      <ScrollReveal>
        <h1 className="text-center text-5xl md:text-7xl">
          <span
            className="bg-gradient-to-r
from-indigo-500
to-blue-500 bg-clip-text text-transparent"
          >
            Recent Projects
          </span>
        </h1>
      </ScrollReveal>
      <div className="flex w-full max-w-[800px] flex-col gap-16 text-white">
        {projectData.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
