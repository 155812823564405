import { motion } from "framer-motion";
import { useState } from "react";
import { BiMenu, BiX } from "react-icons/bi";
import { BsLinkedin, BsYoutube, BsGithub, BsTwitterX } from "react-icons/bs";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.nav
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 z-10 flex w-full items-center justify-between border-b border-b-gray-700 bg-black/70 px-16 py-6 text-white backdrop-blur-d md:justify-evenly"
    >
      <div className="container mx-auto flex justify-between items-center">
        <a
          href="/"
          className="bg-gradient-to-r from-cyan-500 to-pink-300 bg-clip-text text-transparent opacity-80
text-3xl font-semibold transition-all duration-300
hover:opacity-100"
        >
          DATABLOOM
        </a>

        <ul className="hidden md:flex gap-10">
          <a
            href="#home"
            className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
          >
            <li>About us</li>
          </a>

          <a
            href="#services"
            className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
          >
            <li>Services</li>
          </a>

          <a
            href="#projects"
            className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
          >
            <li>Projects</li>
          </a>
          <a
            href="#contactus"
            className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
          >
            <li>Contact</li>
          </a>
        </ul>

        <ul className="hidden md:flex gap-5">
          {/* <li
            className="cursor-pointer text-xl opacity-70 transition-all duration-300 
          hover:text-red-500 hover:opacity-100"
          >
            <BsYoutube />
          </li> */}
          <li
            className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-blue-500
hover:opacity-100"
          >
            <a
              href="https://www.linkedin.com/company/databloom-limited/"
              target="blank"
            >
              <BsLinkedin />
            </a>
          </li>
          {/* <li
            className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-sky-500
hover:opacity-100"
          >
            <BsTwitterX />
          </li> */}
          {/* <li
            className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-orange-500
hover:opacity-100"
          >
            <BsGithub />
          </li> */}
        </ul>

        {isOpen ? (
          <BiX
            className="block md:hidden text-4xl"
            onClick={menuOpen}
          />
        ) : (
          <BiMenu
            className="block md:hidden text-4xl"
            onClick={menuOpen}
          />
        )}

        {isOpen && (
          <div
            className={`fixed right-0 top-[84px] flex h-screen w-1/2 flex-col items-start justify-start gap-10 border-1 border-gray-800 bg-black/90 p-12 ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <ul className="flex flex-col gap-8">
              <a
                href="#home"
                className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
              >
                <li>About us</li>
              </a>

              <a
                href="#services"
                className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
              >
                <li>Services</li>
              </a>

              <a
                href="#projects"
                className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
              >
                <li>Projects</li>
              </a>
              <a
                href="#contactus"
                className="cursor-pointer opacity-70 transition-all duration-300 hover:opacity-100"
              >
                <li>Contact</li>
              </a>
            </ul>

            <ul className="flex flex-wrap gap-5">
              {/* <li className="cursor-pointer text-xl opacity-70 transition-all duration-300 hover:text-red-500 hover:opacity-100">
                <BsYoutube />
              </li> */}
              <li
                className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-blue-500
hover:opacity-100"
              >
                <BsLinkedin />
              </li>
              {/* <li
                className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-sky-500
hover:opacity-100"
              >
                <BsTwitterX />
              </li> */}
              {/* <li
                className="cursor-pointer text-xl opacity-70
transition-all duration-300
hover:text-orange-500
hover:opacity-100"
              >
                <BsGithub />
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </motion.nav>
  );
};

export default Navbar;
