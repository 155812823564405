import React from "react";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Contactus from "./components/Contactus";
import Services from "./components/Services";
import Hero1 from "./components/Hero1";

function App() {
  return (
    <>
      <div className="fixed -z-10 min-h-screen w-full bg-gradient-to-b from-black to-indigo-800"></div>
      <main className="flex flex-col items-center px-4 md:px-8 lg:px-16">
        <Navbar />
        <Hero1 />
        <Services />
        <Projects />
        <Contactus />
      </main>
    </>
  );
}

export default App;
