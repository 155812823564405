import { motion } from "framer-motion";
import image from "../components/hero/4.jpg";

const Hero1 = () => {
  return (
    <div
      id="home"
      className="flex min-h-screen w-full items-center justify-center py-28"
    >
      <div className="flex flex-col md:flex-row items-center justify-center gap-10 text-white">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="md:w-1/2 px-3 xl:pt-12"
        >
          <img
            src={image}
            alt=""
            className="w-full cursor-pointer rounded shadow-xl shadow-indigo-800 transition-all duration-300 hover:-translate-y-5 hover:scale-105 hover:shadow-2x1
hover:shadow-indigo-600 md:h-screen object-cover"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="w-full md:w-1/2 px-3 md:px-10"
          //   className="flex max-w-[400px] flex-col items-center justify-center gap-3 text-center"
        >
          <h1
            className="bg-gradient-to-r
from-indigo-500
to-blue-500 bg-clip-text text-transparent text-4xl font-light  md:text-7x1"
          >
            F&B Tech Provider
          </h1>

          <h3
            className="bg-gradient-to-r
from-white
to-blue-500
bg-clip-text text-transparent text-1xl md:text-2xl"
          >
            Pos System, E-commerce
          </h3>

          <p className="pt-6 text-pretty text-md/7 text-gray-300 text-justify">
            Databloom Limited is a leading technology solution provider
            specialized in empowering restaurants, bars, cafes, and clubs with
            cutting-edge tools to streamline operations, enhance customer
            experiences, and drive growth. Established in 2017 and headquartered
            in the bustling central district of Hong Kong, we are committed to
            delivering innovative, reliable, and scalable solutions tailored to
            the unique needs of the food and beverage (F&B) industry.
          </p>
          <p className="pt-5 text-pretty text-md/7 text-gray-300 text-justify">
            {" "}
            Our mission is to help F&B businesses thrive in a competitive market
            by leveraging technology to simplify operations, increase
            efficiency, and unlock new opportunities for success.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero1;
