import image1 from "../components/services/images.png";
import image2 from "../components/services/ecommerce.png";
import ScrollReveal from "./ScrollReveal";
import ServiceCard from "../components/services/ServiceCard";
import Tech from "./services/Tech";

const servicesData = [
  {
    image: image1,
    title: "Point of Sale (POS) Systems",
    description: [
      {
        title: "Desktop and Mobile POS",
        description:
          "User-friendly, feature-rich POS systems that streamline order management, payments, and inventory tracking.",
      },
      {
        title: "Wifi Printers",
        description:
          "Seamless integration with POS systems for efficient kitchen and receipt printing.",
      },
    ],
  },
  {
    image: image2,
    title: "Web & E-Commerce Solutions",
    description: [
      {
        title: "Online Ordering and payments",
        description:
          "Custom-built online ordering platforms to help businesses expand their reach and offer convenient delivery and pickup options. With Paypal or Hong Kong payments systems integration.",
      },
      {
        title: "Startup, Restaurants, Bar",
        description:
          "Professionally designed websites that showcase your brand, menu, and services, helping you connect with customers and grow your online presence.",
      },
    ],
  },
  // {
  //   image: image3,
  //   title: "Corporate Websites",
  //   description: [
  //     {
  //       title: "Startup, Restaurants, Bar",
  //       description:
  //         "Professionally designed websites that showcase your brand, menu, and services, helping you connect with customers and grow your online presence.",
  //     },
  //   ],
  // },
  // {
  //   image: image4,
  //   title: "Custom Tech Solutions",
  //   description: [
  //     {
  //       title: "Attendance System, Mobile Apps",
  //       description:
  //         "Tailored software and hardware solutions to address specific business challenges and goals. Professionally designed websites that showcase your brand, menu, and services, helping you connect with customers and grow your online presence.",
  //     },
  //   ],
  // },
];

const Services = () => {
  return (
    <div
      id="services"
      className="flex min-h-screen w-full flex-col items-center justify-center gap-16 px-3 md:px-14 md:py-14"
    >
      <ScrollReveal>
        <h1 className="text-center text-5xl md:text-7xl">
          <span
            className="bg-gradient-to-r
from-indigo-500
to-blue-500 bg-clip-text text-transparent"
          >
            Services
          </span>
        </h1>
      </ScrollReveal>
      <div className="flex w-full max-w-[800px] flex-col gap-16 text-white">
        <p>
          <p className="text-gray-300">
            We offer a comprehensive suite of technology solutions designed to
            meet the evolving needs of modern F&B establishments.
          </p>
        </p>
        {servicesData.map((service, index) => (
          <ServiceCard
            key={index}
            service={service}
          />
        ))}
      </div>
      <Tech />
      <div className="flex flex-col md:flex-row items-center justify-center gap-10 text-white">
        <div className="w-full md:w-1/2 md:px-10">
          <ScrollReveal>
            <h1
              className="py-5 text-4xl font-light
md:text-4x1 bg-gradient-to-r from-cyan-500
to-pink-300 bg-clip-text text-transparent"
            >
              Why Choose Us?
            </h1>
          </ScrollReveal>
          <ScrollReveal>
            <ul className="text-gray-300 list-note text-sm/7">
              <li>
                <h2
                  className="text-xl py-2 pt-8 bg-gradient-to-r
from-indigo-300
to-blue-500 bg-clip-text text-transparent"
                >
                  Industry Expertise:
                </h2>
                With years of experience serving the F&B sector, we understand
                the unique challenges and opportunities in the industry.{" "}
              </li>
              <li>
                <h2
                  className="text-xl py-2 pt-8 bg-gradient-to-r
from-indigo-300
to-blue-500 bg-clip-text text-transparent"
                >
                  Innovative Technology:
                </h2>{" "}
                Our solutions are built using the latest technology to ensure
                reliability, scalability, and ease of use.
              </li>
              <li>
                <h2
                  className="text-xl py-2 pt-8 bg-gradient-to-r
from-indigo-300
to-blue-500 bg-clip-text text-transparent"
                >
                  Customer-Centric Approach:{" "}
                </h2>
                We work closely with our clients to deliver customized solutions
                that align with their business goals.
              </li>
            </ul>
          </ScrollReveal>
        </div>
        <div className="w-full md:w-1/2 md:px-10">
          <ScrollReveal>
            <h1
              className="py-5 text-4xl font-light
md:text-6x1 bg-gradient-to-r from-cyan-500
to-pink-300 bg-clip-text text-transparent"
            >
              Our Vision
            </h1>
          </ScrollReveal>
          <ScrollReveal>
            <p className="text-gray-300 text-sm/7">
              To revolutionize the F&B industry by providing innovative,
              accessible, and transformative technology solutions that empower
              businesses to succeed in a digital-first world.
            </p>{" "}
          </ScrollReveal>
          <ScrollReveal>
            <h1
              className="pt-20 pb-5 text-4xl font-light
md:text-6x1 bg-gradient-to-r from-cyan-500
to-pink-300 bg-clip-text text-transparent"
            >
              Let's Build Something Great Together!{" "}
            </h1>
          </ScrollReveal>
          <ScrollReveal>
            <p className="text-gray-300 text-sm/7">
              At Databloom, we believe in the power of collaboration. Whether
              you're a small cafe, a bustling restaurant, or a vibrant
              nightclub, we're here to help you harness the power of technology
              to achieve your goals. Let's work together to create something
              extraordinary.
            </p>
          </ScrollReveal>
        </div>
      </div>
    </div>
  );
};

export default Services;
