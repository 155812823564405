import ScrollReveal from "../ScrollReveal";
const ProjectCard = ({ project }) => {
  return (
    <ScrollReveal>
      <a
        href={project.link}
        target="blank"
      >
        <div className="flex flex-col items-center gap-5  md:flex-row md:gap-24">
          <div className="md:w-1/2">
            <img
              src={project.image}
              alt=""
              className="w-[200px]cursor-pointer rounded-2xl transition-all duration-300
hover:scale-105 md:w-[300px] overflow-hidden"
            />
          </div>
          <div className="flex flex-col gap-5 md:w-1/2">
            <div className="flex flex-col gap-3">
              <p className="text-gray-500 text-sm">
                Project / {project.service}
              </p>
              <div
                className="text-xl font-semibold bg-gradient-to-r
from-indigo-300
to-blue-500 bg-clip-text text-transparent"
              >
                {project.title}
              </div>
              <p className=" text-gray-400">{project.description}</p>
            </div>
            <div className="flex flex-wrap gap-5">
              {project.technologies.map((tech, index) => (
                <span
                  key={index}
                  className="rounded-lg bg-gray-600 p-2 px-3 text-sm text-gray-400"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>
        </div>{" "}
      </a>
    </ScrollReveal>
  );
};

export default ProjectCard;
