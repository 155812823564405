import ScrollReveal from "../ScrollReveal";
const ServiceCard = ({ service }) => {
  console.log(JSON.stringify(service));
  return (
    <ScrollReveal>
      <div className="flex flex-col items-center gap-8 md:flex-row md:gap-24">
        <div className="md:w-1/2">
          <img
            src={service.image}
            alt=""
            className="w-full cursor-pointer rounded-2xl transition-all duration-300
hover:scale-105 overflow-hidden"
          />
        </div>
        <div className="flex flex-col gap-5 md:w-1/2">
          <div className="flex flex-col gap-3">
            <div className="text-2xl font-semibold">
              <h1
                className="bg-gradient-to-r
 from-cyan-500
to-pink-300 bg-clip-text text-transparent"
              >
                {service.title}
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap gap-5">
            {service.description.map((item, index) => (
              <div>
                <h2
                  className="text-xl bg-gradient-to-r
from-indigo-300
to-blue-500 bg-clip-text text-transparent"
                  key={index}
                >
                  {item.title}
                </h2>
                <p className="text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ScrollReveal>
  );
};

export default ServiceCard;
