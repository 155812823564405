import {
  BiLogoJavascript,
  BiLogoPhp,
  BiLogoPostgresql,
  BiLogoPython,
  BiLogoReact,
  BiLogoTailwindCss,
} from "react-icons/bi";
import sambapos from "../services/sambapos_logo.png";
import { motion } from "framer-motion";

const Tech = () => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <>
      <motion.h1
        variants={variants}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-4xl font-light
md:text-6x1 bg-gradient-to-r from-cyan-500
to-pink-300 bg-clip-text text-transparent"
      >
        Technologies
      </motion.h1>
      <div className="flex flex-wrap items-center justify-center gap-10 p-5">
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoJavascript
            className="cursor-pointer text-[80px]
text-orange-500 transition-all duration-300
hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoReact
            className="cursor-pointer text-[80px]
text-sky-500 transition-all duration-300
hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoPython
            className="cursor-pointer text-[80px]
text-yellow-400 transition-all duration-300
hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>

        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoPhp
            className="cursor-pointer text-[80px]
text-green-400 transition-all 
duration-300 hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoTailwindCss
            className="cursor-pointer text-[80px]
text-sky-400 transition-all 
duration-300 hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>

        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BiLogoPostgresql
            className="cursor-pointer text-[80px]
text-blue-500 transition-all 
duration-300 hover:-translate-y-5 sm:text-[100px] md:text-[120px]"
          />
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img
            src={sambapos}
            alt=""
            className="-pt-5 pl-5 cursor-pointer w-[60px]
transition-all 
duration-300 hover:-translate-y-5   md:w-[75px]"
          />
        </motion.div>
      </div>
    </>
  );
};

export default Tech;
