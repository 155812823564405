const Contact = () => {
  return (
    <>
      <div
        id="contactus"
        className="flex min-h-[70vh] max-w-[800px] items-center justify-center"
      >
        <div className="flex flex-col items-center justify-center gap-3 space-y-6 md:p-14">
          <h1 className="text-center text-5xl md:text-7xl">
            <span
              className="bg-gradient-to-r
from-indigo-500
to-blue-500 bg-clip-text text-transparent"
            >
              Get in Touch
            </span>
          </h1>
          <p className="text-center text-lg font-semibold text-gray-400">
            Want to chat? We are based in Hong Kong. Send us an E-mail through
            this button and we'll respond ASAP.
          </p>
          <a
            href="mailto:info@databloom.com.hk"
            className="text-nowrap rounded-lg border
border-indigo-600 bg-black px-5 py-3 text-lg font-bold text-white shadow-1g
shadow-indigo-700 transition-all
duration-300 hover: -translate-y-2 hover: shadow-xl hover:shadow-indigo-600"
          >
            Contact Us
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;
